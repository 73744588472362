/* Common */
$textColor:#212121;
$textSecondaryColor:#757575;
$fontSize:14px;
$lineHeight:1.5em;
$transitionDuration:.3s;
$borderRadius:3px;

/* Common */
$fontFamily:"Open Sans","Helvetica Neue",sans-serif;
$iconWidth:16px;
$iconHeight:16px;

/* Layout */
$bodyBgColor:#f7f7f7;
$maskBgColor:rgba(0, 0, 0, 0.4);;
$topbarButtonColor:#ffffff;
$topbarButtonHoverColor:#e8e8e8;
$topbarSearchBorderColor:#ffffff;
$topbarSearchColor:#ffffff;
$topbarIconColor:#ffffff;
$topbarIconHoverColor:#e8e8e8;
$topbarMenuBgColor:#ffffff;
$layoutMenuBgColor:#ffffff;
$layoutMenuScrollbarBgColor:#aaaaaa;
$layoutMenuItemIconColor:#757575;
$layoutMenuItemActiveColor:#e8e8e8;
$horizontalLayoutMenuItemTextColor:#ffffff;
$horizontalLayoutMenuItemHoverBgColor:#e8e8e8;
$horizontalLayoutMenuSubMenuBgColor:#ffffff;
$horizontalLayoutMenuSubMenuActiveBgColor:#e8e8e8;
$horizontalDarkLayoutMenuItemTextColor:#ffffff;
$slimLayoutMenuSubMenuBgColor:#ffffff;
$slimMenuTooltipColor:#ffffff;
$rightPanelBgColor:#ffffff;
$topbarSubmenuBgColor:#ffffff;
$profileMenuBorderBottomColor:#d6d5d5;
$profileMenuDarkBorderBottomColor:#545454;
$darkMenuColor:#ffffff;
$formGroupPadding:1em;

/* Theme */
$headerFontSize:1em;
$headerPadding:.625em 1em;
$contentFontSize:1em;
$contentPadding:.625em 1em;
$inputHeaderFontSize:1em;
$inputHeaderPadding:.625em 1em;
$inputBorderColor:#bdbdbd;
$inputBorderErrorColor:#e62a10;
$inputErrorTextColor:#e62a10;
$textboxBgColor:#f7f7f7;
$inputFontSize:1em;
$buttonFontSize:1em;
$inputOptionFontSize:1em;
$inputOptionPadding:.625em .875em;
$hoverBgColor:#e8e8e8;
$hoverTextColor:#000000;
$dividerColor:#bdbdbd;
$dividerLightColor:#cacaca;
$grayBgColor:#757575;
$iconFontSize:1.5em;
$invalidInputLabelColor:#e62a10;
$invalidInputBorderColor:#e62a10;
$activeMenuItemBadgeColor:#ffffff;
$activeMenuItemBadgeTextColor:#212121;
$datatableEvenRowBgColor:#f4f4f4;
$inlineSpacing:.5em;

/* Compact */
$c-fontSize:14px;
$c-lineHeight:18px;

/* Contents */
$contentPadding:.5em 1em;
$contentBorderColor:#c9cdd2;
$contentBgColor:#ffffff;


/* Headers */
$headerPadding:.5em 1em;
$headerBgColor:#2d353c;
$headerTextColor:#ffffff;
$headerHoverBgColor:#444d56;

/* Forms */
$inputPadding:.35em;
$inputBgColor:#ffffff;
$inputBorderColor:#a8acb1;
$inputHoverBorderColor:#5f666c;
$inputInvalidBorderColor:#f06364;
$inputGroupAddonBgColor:#e6e6e6;

$buttonTextColor: #ffffff;

/* List Items */
$listItemPadding:.5em 1em;
$listItemHoverBgColor:#f0f3f5;
$listItemHoverTextColor:#5f666c;

/* Messages */
$infoMessageBgColor:#2f8ee5;
$infoMessageTextColor:#ffffff;
$warnMessageBgColor:#f1b263;
$warnMessageTextColor:#ffffff;
$errorMessageBgColor:#f06364;
$errorMessageTextColor:#ffffff;
$successMessageBgColor:#57c279;
$successMessageTextColor:#ffffff;


/* Predefined Colors */
$turquoise:#00acac;
$blue:#2f8ee5;
$orange:#efa64c;
$purple:#6c76af;
$pink:#f16383;
$lightBlue:#63c9f1;
$green:#57c279;
$deepPurple:#7e57c2;
$black:#2d353c;
$red:#EF5350;
