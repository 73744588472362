:root {
  --primaryColor: #ff5722;
  --primaryDarkColor: #e64a19;
  --primaryLightColor: #eeeeee;
}

$primaryColor: #ff5722;
$primaryDarkColor: #e64a19;
$primaryLightColor: #eeeeee;

$accentColor: #ff5722;
$accentDarkColor: #d84315;
$accentLightColor: #ffab91;
$accentTextColor: #ffffff;

/* Theme */
$headerFontSize: 1em;
$headerPadding: 0.625em 1em;
$contentFontSize: 1em;
$contentPadding: 0.625em 1em;
$inputHeaderFontSize: 1em;
$inputHeaderPadding: 0.625em 1em;
$inputBorderColor: #bdbdbd;
$inputBorderErrorColor: #e62a10;
$inputErrorTextColor: #e62a10;
$textboxBgColor: #f7f7f7;
$inputFontSize: 1em;
$buttonFontSize: 1em;
$inputOptionFontSize: 1em;
$inputOptionPadding: 0.625em 0.875em;
$hoverBgColor: #e8e8e8;
$hoverTextColor: #000000;
$dividerColor: #bdbdbd;
$dividerLightColor: #cacaca;
$grayBgColor: #757575;
$iconFontSize: 1.5em;
$invalidInputLabelColor: #e62a10;
$invalidInputBorderColor: #e62a10;
$activeMenuItemBadgeColor: #ffffff;
$activeMenuItemBadgeTextColor: #212121;
$datatableEvenRowBgColor: #f4f4f4;
$inlineSpacing: 0.5em;
