.splash-screen {
    background-color: #ffffff;
    height: 100%;
    width: 100%;
    position: fixed;
    
    .splash-container {
        padding-top: 200px;
        width: 250px;
        margin: 0 auto;
        text-align: center;
    }
    
    .box {
      width: 300px;
      height: 300px;
      @include border-radius(50%);
      background: #ffffff;
      position: relative;
      overflow: hidden;
      transform: translate3d(0, 0, 0);
    }

    .wave {
      opacity: .4;
      position: absolute;
      top: 3%;
      left: 50%;
      background: #293978;
      width: 500px;
      height: 500px;
      margin-left: -250px;
      margin-top: -250px;
      transform-origin: 50% 48%;
      border-radius: 43%;
      animation: drift 3000ms infinite linear;
    }

    .wave.-three {
      animation: drift 2500ms infinite linear;
    }

    .wave.-two {
      animation: drift 5000ms infinite linear;
      opacity: .1;
      background: #293978;
    }

    .box:after {
      content: '';
      display: block;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(to bottom, rgba(#00acac, 1), rgba(#293978, 0) 80%, rgba(white, .5));
      z-index: 11;
      transform: translate3d(0, 0, 0);
    }

    .title {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      z-index: 1;
      line-height: 300px;
      text-align: center;
      transform: translate3d(0, 0, 0);
      color: white;
      text-transform: uppercase;
      font-family: 'Playfair Display', serif;
      letter-spacing: .4em;
      font-size: 24px;
      text-shadow: 0 1px 0 rgba(black, .1);
      text-indent: .3em;
    }
}

@keyframes drift {
  from { transform: rotate(0deg); }
  from { transform: rotate(360deg); }
}

@-moz-keyframes spin { 100% { -moz-transform: rotate(360deg); } }
@-webkit-keyframes spin { 100% { -webkit-transform: rotate(360deg); } }
@keyframes spin { 100% { -webkit-transform: rotate(360deg); transform:rotate(360deg); } }