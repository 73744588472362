html {
  height: 100%;
}

body {
  font-family: Roboto, 'Helvetica Neue', sans-serif;
  font-size: $fontSize;
  line-height: $lineHeight;
  color: $textColor;
  -webkit-font-smoothing: antialiased;
  padding: 0;
  margin: 0;
  background-color: $bodyBgColor;
  min-height: 100%;

  a {
    text-decoration: none;
  }
}

.layout-mask {
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: $maskBgColor;
  top: 0;
  left: 0;
  z-index: 999999997;
  @include opacity(0.7);
}

.layout-container {
  .topbar {
    position: fixed;
    z-index: 100;
    width: 100%;
    height: 75px;
    background-color: $primaryColor;
    @include shadow(0 2px 5px 0 rgba(0, 0, 0, 0.26));

    .logo {
      display: inline-block;
      vertical-align: middle;
      width: 200px;
      height: 30px;
      background: var(--logo-url) top left no-repeat;
      background-size: 200px 30px;
    }

    .topbar-left {
      box-sizing: border-box;
      padding: 20px;
      height: 75px;
      width: 250px;
      background-color: $primaryDarkColor;
      float: left;
      @include shadow(3px 0 6px rgba(0, 0, 0, 0.3));
    }

    .topbar-right {
      padding: 15px;
      position: relative;

      #menu-button {
        color: $accentTextColor;
        display: inline-block;
        vertical-align: middle;
        height: 36px;
        margin-right: 10px;
        position: relative;
        left: -16px;
        top: 3px;
        background-color: $accentColor;
        width: 2.5rem;
        height: 2.5rem;
        display: inline-flex;
        align-items: center;
        justify-content: center;

        @include border-radius(50%);
        @include transition(all $transitionDuration);
        @include multi-shadow(
          0 3px 10px rgba(0, 0, 0, 0.23),
          0 3px 10px rgba(0, 0, 0, 0.16)
        );

        &:hover {
          @include scale(1.2);
        }

        i {
          // @include material-icon("chevron_left");
          @include transition(all $transitionDuration);
          font-size: 23px;
        }
      }

      #topbar-menu-button,
      #rightpanel-menu-button {
        display: none;
        color: $topbarButtonColor;
        vertical-align: middle;
        height: 36px;
        margin-top: 4px;
        float: right;

        @include transition(all $transitionDuration);

        i {
          @include transition(color $transitionDuration);
          font-size: 36px;
        }
      }

      #rightpanel-menu-button {
        display: block;

        &:hover {
          color: $topbarButtonHoverColor;
        }
      }

      .topbar-items {
        .search-item {
          input {
            position: relative;
            top: -10px;
            font-size: $fontSize;
            background-color: transparent;
            background-image: linear-gradient(to bottom, #ffffff, #ffffff),
              linear-gradient(
                to bottom,
                lighten($primaryColor, 25%),
                lighten($primaryColor, 25%)
              );
            border-width: 0;
            padding: 2px;
            color: $topbarSearchColor;

            &:focus {
              outline: 0 none;
            }
          }

          input:focus ~ label {
            top: -5px;
            font-size: 12px;
          }

          input.ui-state-filled ~ label {
            display: none;
          }

          label {
            color: $topbarSearchColor;
            top: 8px;
          }
        }
      }
    }
  }

  .layout-menu {
    overflow: visible;
    position: fixed;
    width: 250px;
    z-index: 99;
    top: 75px;
    height: 100%;
    background-color: $layoutMenuBgColor;
    @include shadow(3px 0 6px rgba(0, 0, 0, 0.3));
    @include transition(margin-left $transitionDuration);

    .ui-scrollpanel {
      background: transparent;
      border-radius: 0;
      border: none;
      .ui-scrollpanel-bar {
        background-color: $layoutMenuScrollbarBgColor;
        @include opacity(0.3);
      }
      .ui-scrollpanel-hidden {
        display: block;
        visibility: hidden;
      }

      .ui-scrollpanel-content {
        overflow-x: hidden;
      }

      .layout-menu {
        padding-bottom: 120px;
      }
    }

    .profile {
      box-sizing: border-box;
      padding-top: 2em;
      width: 250px;
      height: 145px;
      text-align: center;
      background: url('../../layout/images/profile-bg.png') top left no-repeat;
      background-size: 250px 145px;
      @include shadow(0 2px 5px 0 rgba(0, 0, 0, 0.16));

      .profile-image {
        width: 60px;
        height: 60px;
        margin: 0 auto 5px auto;
        display: block;
      }

      .profile-name {
        display: inline-block;
        color: $textColor;
        vertical-align: middle;
        font-size: 1em;
      }

      i {
        color: $textColor;
        vertical-align: middle;
        @include transition(transform $transitionDuration);
      }

      &.profile-expanded {
        i {
          @include rotate(-180deg);
        }
      }
    }

    .profile-menu {
      border-bottom: 1px solid $profileMenuBorderBottomColor;
      overflow: hidden;

      li:first-child {
        margin-top: 1em;
      }

      li:last-child {
        margin-bottom: 1em;
      }
    }

    &.layout-menu-dark {
      background-color: $darkMenuBgColor;

      .profile {
        background-image: url('../../layout/images/profile-bg-dark.png');

        .profile-name {
          color: $darkMenuColor;
        }

        i {
          color: $darkMenuColor;
        }
      }

      .profile-menu {
        border-bottom: 1px solid $profileMenuDarkBorderBottomColor;
      }

      .ui-scrollpanel {
        .ui-scrollpanel-wrapper {
          .ui-scrollpanel-content {
            border-radius: 0;
            border: none;
          }
        }
      }

      ul.ultima-menu {
        li {
          a {
            color: $darkMenuColor;

            i {
              color: $darkMenuColor;
            }

            &:hover {
              background-color: $darkMenuHoverColor;
            }

            &.active-menuitem-routerlink {
              color: $darkMenuRouterLinkActiveColor;

              i {
                color: $darkMenuRouterLinkActiveColor;
              }

              &:hover {
                color: $darkMenuColor;

                > i {
                  color: $darkMenuColor;
                }
              }
            }
          }

          &.active-menuitem {
            > a {
              color: $accentTextColor;
              background-color: $accentColor;

              &.active-menuitem-routerlink {
                color: $accentTextColor;
                background-color: $accentColor;

                i {
                  color: $textColor;
                }
              }
            }
          }
        }
      }
    }

    .menuitem-badge {
      position: absolute;
      right: 3.5em;
      top: 0.75em;
      display: inline-block;
      width: 1em;
      height: 1em;
      margin-right: 0.5em;
      text-align: center;
      background-color: $accentColor;
      color: $accentTextColor;
      font-size: $fontSize - 2;
      font-weight: 700;
      line-height: 1em;
      @include border-radius(50%);
    }

    .layout-menu-tooltip {
      display: none;
      padding: 0 5px;
      position: absolute;
      left: 76px;
      top: 6px;
      z-index: 101;
      line-height: 1;

      .layout-menu-tooltip-text {
        padding: 6px 8px;
        font-weight: 700;
        background-color: darken($darkMenuBgColor, 5%);
        color: $slimMenuTooltipColor;
        min-width: 75px;
        white-space: nowrap;
        text-align: center;
        @include border-radius($borderRadius);
        @include shadow(0 6px 12px rgba(0, 0, 0, 0.175));
      }

      .layout-menu-tooltip-arrow {
        position: absolute;
        width: 0;
        height: 0;
        border-color: transparent;
        border-style: solid;
        top: 50%;
        left: 0;
        margin-top: -5px;
        border-width: 5px 5px 5px 0;
        border-right-color: darken($darkMenuBgColor, 5%);
      }
    }
  }

  &.menu-layout-overlay {
    .layout-menu {
      margin-left: -250px;
    }

    .layout-main {
      margin-left: 0px;
    }

    &.layout-menu-overlay-active {
      .layout-menu {
        z-index: 999999999;
        margin-left: 0px;
      }

      .layout-mask {
        display: block;
      }

      .topbar {
        .topbar-right {
          #menu-button {
            i {
              @include rotate(0deg);
            }
          }
        }
      }
    }

    .topbar {
      z-index: 999999998;

      .topbar-right {
        #menu-button {
          i {
            font-size: 36px !important;
            @include rotate(180deg);
          }
        }
      }
    }
  }

  .layout-main {
    padding: 75px 0 0 0;

    .layout-content {
      padding: 16px;
    }
    @include transition(margin-left $transitionDuration);
  }

  .layout-mask {
    display: none;
  }

  .layout-breadcrumb {
    background-color: #ffffff;
    @include shadow(inset 0 -2px 4px 0 rgba(0, 0, 0, 0.14));
    min-height: 42px;
    @include clearfix();

    ul {
      margin: 8px 0 0 0;
      padding: 0 0 0 20px;
      list-style: none;
      color: $textSecondaryColor;
      display: inline-block;

      li {
        display: inline-block;
        vertical-align: top;
        color: $textSecondaryColor;

        &:nth-child(even) {
          font-size: 20px;
        }

        &:first-child(even) {
          color: $primaryColor;
        }

        a {
          color: $textSecondaryColor;
        }
      }
    }

    .layout-breadcrumb-options {
      float: right;
      padding: 0px 20px 0 0;
      height: 100%;

      a {
        color: $textSecondaryColor;
        display: inline-block;
        width: 42px;
        height: 42px;
        line-height: 42px;
        text-align: center;
        @include transition(background-color $transitionDuration);

        &:hover {
          background-color: $hoverBgColor;
        }

        i {
          line-height: inherit;
        }
      }
    }
  }

  .ultima-menu {
    margin: 0;
    padding: 0;
    list-style: none;
    width: 268px;

    &.ultima-main-menu {
      margin-top: 16px;
      padding-bottom: 120px;
    }

    li {
      a {
        font-size: 1em;
        display: block;
        padding: 0.5em 2.5em 0.5em 1em;
        color: $textColor;
        width: 100%;
        box-sizing: border-box;
        @include transition(background-color $transitionDuration);
        overflow: hidden;

        i {
          color: $layoutMenuItemIconColor;
        }

        i:first-child {
          display: inline-block;
          vertical-align: middle;
          margin-right: 0.5em;
          font-size: 1.5em;
        }

        i:last-child {
          float: right;
          font-size: $fontSize + 4;
          margin-top: 0.15em;
          margin-right: -0.15em;
          @include transition(transform $transitionDuration);
        }

        &:hover {
          background-color: $hoverBgColor;
        }

        span {
          display: inline-block;
          vertical-align: middle;
        }

        &.active-menuitem-routerlink {
          color: $lightMenuRouterLinkActiveColor;

          > i {
            color: $lightMenuRouterLinkActiveColor;
          }

          &:hover {
            color: $textColor;

            > i {
              color: $layoutMenuItemIconColor;
            }
          }
        }
      }

      &.active-menuitem {
        > a {
          color: $primaryColor;
          background-color: $layoutMenuItemActiveColor;

          i {
            color: $primaryColor;
          }

          i:last-child {
            @include rotate(-180deg);
          }
        }
      }

      ul {
        padding: 0;
        margin: 0;
        list-style: none;
        overflow: hidden;

        li {
          a {
            padding: 0.5em 2.5em 0.5em 2em;

            > span {
              font-size: $fontSize - 1;
            }

            i:first-child {
              display: inline-block;
              vertical-align: middle;
              margin-right: 0.6em;
              font-size: 1.25em;
            }
          }

          ul {
            li {
              a {
                padding-left: 3em;
              }
            }

            ul {
              li {
                a {
                  padding-left: 4em;
                }
              }

              ul {
                li {
                  a {
                    padding-left: 5em;
                  }
                }

                ul {
                  li {
                    a {
                      padding-left: 6em;
                    }
                  }
                }
              }
            }
          }
        }
      }

      &.red-badge {
        > a {
          .menuitem-badge {
            background-color: #f44336;
            color: #ffffff;
          }
        }
      }

      &.purple-badge {
        > a {
          .menuitem-badge {
            background-color: #4527a0;
            color: #ffffff;
          }
        }
      }

      &.teal-badge {
        > a {
          .menuitem-badge {
            background-color: #00695c;
            color: #ffffff;
          }
        }
      }
    }
  }

  .footer {
    padding: 0.5em;

    .footer-text-left {
      float: left;
    }

    .footer-text-right {
      color: $textSecondaryColor;
      float: right;

      span {
        vertical-align: middle;
        display: inline-block;
      }
    }
  }

  .layout-rightpanel {
    position: fixed;
    top: 75px;
    height: 100%;
    right: -240px;
    width: 240px;
    z-index: 100;
    background-color: $rightPanelBgColor;
    @include box-sizing(border-box);
    @include transition(right $transitionDuration);
    @include shadow(0 2px 10px 0 rgba(0, 0, 0, 0.3));

    &.layout-rightpanel-active {
      right: 0;
      -webkit-transition-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
      transition-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
    }

    .layout-rightpanel-content {
      padding: 14px;
      padding-bottom: 120px;
    }

    .ui-scrollpanel {
      border-radius: 0;
      border: none;
      .ui-scrollpanel-hidden {
        display: block;
        visibility: hidden;
      }
      .layout-rightpanel-wrapper {
        width: 258px;
        padding-right: 18px;
      }
    }
  }
}

.ajax-loader {
  font-size: 2em;
  color: $accentColor;
}

@media (min-width: 1025px) {
  .layout-container {
    .topbar-items {
      float: right;
      margin: 0;
      padding: 5px 0 0 0;
      list-style-type: none;

      > li {
        float: right;
        position: relative;
        margin-left: 8px;

        > a {
          position: relative;
          display: block;

          .topbar-item-name {
            display: none;
          }

          .topbar-badge {
            position: absolute;
            right: -5px;
            top: -5px;
            background-color: $accentColor;
            color: $accentTextColor;
            @include border-radius(50%);
            padding: 2px 4px;
            display: block;
            font-size: 12px;
            line-height: 12px;
          }
        }

        .topbar-icon {
          font-size: 36px;
          color: $topbarIconColor;
          @include transition(color $transitionDuration);

          &:hover {
            color: $topbarIconHoverColor;
          }
        }

        &.profile-item {
          .profile-image {
            width: 36px;
            height: 36px;
          }
        }

        > ul {
          position: absolute;
          top: 55px;
          right: 5px;
          display: none;
          width: 250px;
          background-color: $topbarSubmenuBgColor;
          @include multi-shadow(
            0 6px 20px 0 rgba(0, 0, 0, 0.19),
            0 8px 17px 0 rgba(0, 0, 0, 0.2)
          );
          -webkit-animation-duration: $transitionDuration;
          -moz-animation-duration: $transitionDuration;
          animation-duration: $transitionDuration;
        }

        &.active-top-menu {
          > ul {
            display: block;
          }
        }

        .topbar-message {
          img {
            display: inline-block;
            vertical-align: middle;
            margin-right: 12px;
          }
        }
      }
    }

    &.menu-layout-static {
      .layout-menu {
        margin-left: 0;
      }

      .layout-main {
        margin-left: 250px;
      }

      &.layout-menu-static-inactive {
        .topbar {
          .topbar-right {
            #menu-button {
              i {
                @include rotate(180deg);
              }
            }
          }
        }

        .layout-menu {
          margin-left: -250px;
        }

        .layout-main {
          margin-left: 0px;
        }
      }

      .layout-mask {
        display: none;
      }
    }

    &.menu-layout-horizontal {
      .topbar {
        @include no-shadow();

        .topbar-left {
          background-color: $primaryColor;
          @include no-shadow();
        }

        .topbar-right {
          #menu-button {
            display: none;
          }
        }
      }

      .layout-menu {
        overflow: visible;
        position: fixed;
        width: 100%;
        top: 75px;
        height: auto;
        background-color: $primaryDarkColor;
        @include shadow(0 2px 5px 0 rgba(0, 0, 0, 0.26));

        .ui-scrollpanel {
          overflow: visible;
          border: none;
          .ui-scrollpanel-wrapper {
            overflow: visible;
            .ui-scrollpanel-content {
              overflow: visible;
            }
          }
        }

        ul.ultima-menu {
          width: 100%;
          &.ultima-main-menu {
            margin-top: 0;
            padding-bottom: 0;
          }

          > li {
            float: left;
            position: relative;

            > a {
              padding: 0.5em 1em;
              color: $horizontalLayoutMenuItemTextColor;

              i {
                color: $horizontalLayoutMenuItemTextColor;
              }

              &:hover {
                background-color: $horizontalLayoutMenuItemHoverBgColor;
                color: $textColor;

                i {
                  color: $textColor;
                }
              }

              &.active-menuitem-routerlink {
                color: $horizontalLightMenuRouterLinkActiveColor;

                > i {
                  color: $horizontalLightMenuRouterLinkActiveColor;
                }

                &:hover {
                  color: $textColor;

                  i {
                    color: $textColor;
                  }
                }
              }
            }

            > ul {
              position: absolute;
              top: 41px;
              left: 0px;
              width: 250px;
              background-color: $horizontalLayoutMenuSubMenuBgColor;
              @include multi-shadow(
                0 6px 20px 0 rgba(0, 0, 0, 0.19),
                0 8px 17px 0 rgba(0, 0, 0, 0.2)
              );

              li {
                a {
                  padding: 10px 16px;
                }

                ul {
                  position: static;

                  li {
                    a {
                      padding-left: 32px;
                    }
                  }

                  ul {
                    li {
                      a {
                        padding-left: 48px;
                      }
                    }

                    ul {
                      li {
                        a {
                          padding-left: 64px;
                        }
                      }

                      ul {
                        li {
                          a {
                            padding-left: 80px;
                          }
                        }

                        ul {
                          li {
                            a {
                              padding-left: 96px;
                            }
                          }
                        }
                      }
                    }
                  }
                }

                &.active-menuitem {
                  > a {
                    color: $primaryColor;
                    background-color: $horizontalLayoutMenuSubMenuActiveBgColor;
                  }

                  > ul {
                    display: block;
                  }
                }
              }
            }

            &.active-menuitem {
              > a {
                color: $accentTextColor;
                background-color: $accentColor;

                i {
                  color: $accentTextColor;
                }
              }

              > ul {
                display: block;
              }
            }
          }
        }

        &.layout-menu-dark {
          background-color: $darkMenuBgColor;

          ul.ultima-menu {
            li {
              a {
                color: $horizontalDarkLayoutMenuItemTextColor;

                &:hover {
                  background-color: $darkMenuHoverColor;
                  color: $horizontalDarkLayoutMenuItemTextColor;

                  i {
                    color: $horizontalDarkLayoutMenuItemTextColor;
                  }
                }

                &.active-menuitem-routerlink {
                  color: $darkMenuRouterLinkActiveColor;

                  i {
                    color: $darkMenuRouterLinkActiveColor;
                  }

                  &:hover {
                    color: $horizontalDarkLayoutMenuItemTextColor;

                    i {
                      color: $horizontalDarkLayoutMenuItemTextColor;
                    }
                  }
                }
              }

              &.active-menuitem {
                > a {
                  color: $accentTextColor;
                  background-color: $accentColor;
                }
              }

              ul {
                background-color: $darkMenuBgColor;
              }
            }
          }
        }

        .menuitem-badge {
          left: 32px;
          top: 7px;
        }

        .active-menuitem {
          .menuitem-badge {
            background-color: $activeMenuItemBadgeColor;
            color: $activeMenuItemBadgeTextColor;
          }
        }
      }

      .layout-main {
        padding-top: 116px;
        margin-left: 0px;
      }

      .layout-mask {
        display: none;
      }
    }

    &.menu-layout-slim {
      .topbar {
        left: 75px;
        width: calc(100% - 75px);

        .topbar-left {
          background: transparent;
          @include no-shadow();
        }

        .topbar-right {
          #menu-button {
            display: none;
          }
        }
      }

      .layout-menu {
        width: 75px;
        overflow: visible;
        z-index: 100;
        top: 0;

        .profile {
          width: calc(100% - 18px);
          height: 74px;
          padding-top: 15px;

          > a {
            .profile-image {
              width: 45px;
              height: 45px;
            }

            .profile-name,
            i {
              display: none;
            }
          }
        }

        .ui-scrollpanel {
          border: none;
          border-radius: 0;
          .ui-scrollpanel-wrapper {
            overflow: visible;
            .ui-scrollpanel-content {
              overflow: visible;
            }
          }

          .ui-scrollpanel-bar {
            display: none;
          }
        }

        .ultima-menu {
          padding: 0;
          width: calc(100% - 18px);

          > li {
            position: relative;

            > a {
              text-align: center;
              padding-left: 0;
              padding-right: 0;
              padding-top: 0.5em;
              padding-bottom: 0.5em;

              i:first-child {
                font-size: 1.75em;
                margin-right: 0;
              }

              span,
              .submenu-icon {
                display: none;
              }

              &:hover + .layout-menu-tooltip {
                display: block;
              }
            }

            > ul {
              background-color: $slimLayoutMenuSubMenuBgColor;
              position: absolute;
              top: 0;
              left: 75px;
              min-width: 200px;
              @include shadow(0 2px 5px 0 rgba(0, 0, 0, 0.26));

              li {
                a {
                  padding: 0.5em 1em 0.5em 2em;
                  padding-left: 16px;
                }

                ul {
                  li {
                    a {
                      padding-left: 32px;
                    }
                  }

                  ul {
                    li {
                      a {
                        padding-left: 48px;
                      }
                    }

                    ul {
                      li {
                        a {
                          padding-left: 64px;
                        }
                      }

                      ul {
                        li {
                          a {
                            padding: 80px;
                          }
                        }

                        ul {
                          li {
                            a {
                              padding: 96px;
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }

            &.active-menuitem {
              > a {
                &:hover + .layout-menu-tooltip {
                  display: none;
                }
              }
            }
          }
        }

        &.layout-menu-dark {
          .ultima-menu {
            > li {
              > ul {
                background-color: $darkMenuBgColor;
              }
            }
          }
        }
      }

      .layout-main {
        margin-left: 75px;
      }

      .layout-footer {
        margin-left: 75px;
      }
    }
  }
}

@media (max-width: 1024px) {
  .layout-container {
    &.menu-layout-static {
      .topbar {
        .topbar-right {
          #menu-button {
            i {
              @include rotate(180deg);
            }
          }
        }
      }
      .layout-menu {
        margin-left: -265px;
      }

      .layout-main {
        margin-left: 0px;
      }

      &.layout-menu-static-active {
        .layout-menu {
          margin-left: 0;
          z-index: 999999999;
        }

        .topbar {
          z-index: 999999998;

          .topbar-right {
            #menu-button {
              i {
                @include rotate(0);
              }
            }
          }
        }

        .layout-main {
          margin-left: 0;
        }

        .layout-mask {
          display: block;
        }
      }
    }

    .topbar {
      .topbar-right {
        #topbar-menu-button {
          display: block;
        }

        .topbar-items {
          position: absolute;
          top: 75px;
          right: 15px;
          width: 275px;
          display: none;
          background-color: $topbarMenuBgColor;
          @include multi-shadow(
            0 6px 20px 0 rgba(0, 0, 0, 0.19),
            0 8px 17px 0 rgba(0, 0, 0, 0.2)
          );
          @include animation-duration($transitionDuration);
          list-style-type: none;
          margin: 0;
          padding: 0;

          > li {
            > a {
              width: 100%;
              display: block;
              box-sizing: border-box;
              font-size: 16px;
              padding: 16px 16px;
              color: $textColor;
              position: relative;

              i {
                display: inline-block;
                vertical-align: middle;
                margin-right: 12px;
                font-size: 24px;
              }

              &:hover {
                background-color: $topbarIconHoverColor;
              }

              .topbar-item-name {
                display: inline-block;
                vertical-align: middle;
              }

              .topbar-badge {
                position: absolute;
                left: 30px;
                top: 10px;
                background-color: $accentColor;
                color: $accentTextColor;
                @include border-radius(50%);
                padding: 2px 4px;
                display: block;
                font-size: 12px;
                line-height: 12px;
              }
            }

            > ul {
              display: none;

              li {
                a {
                  span,
                  img,
                  i {
                    display: inline-block;
                    vertical-align: middle;
                  }
                }
              }
            }

            &.active-top-menu {
              > a {
                color: $primaryColor;
              }

              > ul {
                display: block;

                li {
                  a {
                    padding-left: 32px;
                  }
                }
              }
            }

            &.search-item {
              input {
                background-image: linear-gradient(
                    to bottom,
                    $primaryColor,
                    $primaryColor
                  ),
                  linear-gradient(
                    to bottom,
                    desaturate(lighten($primaryColor, 25%), 25%),
                    desaturate(lighten($primaryColor, 25%), 25%)
                  );
              }
            }

            &.search-item {
              text-align: center;
              width: 100%;
              display: block;
              box-sizing: border-box;
              font-size: 16px;
              padding: 16px 16px;
              position: relative;

              input {
                top: 0;
                width: 100%;
                box-sizing: border-box;
                padding-right: 16px;
                border-color: $dividerColor;
                color: $textColor;

                &:focus {
                  border-color: $dividerColor;
                }
              }

              input:focus ~ label,
              input.ui-state-filled ~ label {
                top: -20px;
                color: $primaryColor;
              }

              label {
                top: 1px;
                color: $textColor;
              }

              i {
                position: absolute;
                right: 5px;
                top: -2px;
              }
            }

            &.profile-item {
              .profile-image {
                display: inline-block;
                vertical-align: middle;
                width: 24px;
                height: 24px;
                background: url('../../layout/images/avatar.png') top left
                  no-repeat;
                background-size: 24px 24px;
                margin-right: 14px;
              }

              span {
                vertical-align: middle;
                display: inline-block;
              }
            }
          }

          &.topbar-items-visible {
            display: block;
          }
        }
      }
    }
  }
}

@media (max-width: 385px) {
  .layout-container {
    .topbar {
      .topbar-right {
        #topbar-menu-button {
          position: absolute;
          height: 1.5em;
          right: 24px;
          top: 1.375em;

          i {
            font-size: 1.5em;
          }
        }

        #rightpanel-menu-button {
          position: absolute;
          height: 1.5em;
          right: 8px;
          top: 1.375em;

          i {
            font-size: 1.5em;
          }
        }

        #menu-button {
          margin-right: 0;
        }
      }
    }
  }
}
