$primaryColor: #757575;
$primaryDarkColor: #424242;
$primaryLightColor: #eeeeee;
$accentColor: #0097a7;
$accentDarkColor: #008593;
$accentLightColor: #ffab91;
$accentTextColor: #ffffff;
$darkMenuBgColor: #424242;
$darkMenuHoverColor: #676767;
$darkMenuRouterLinkActiveColor: #ff5722;
$lightMenuRouterLinkActiveColor: #ff5722;
$horizontalLightMenuRouterLinkActiveColor: #ff5722;

@import '../../sass/layout/_layout';
