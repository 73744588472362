/* Utils */
.clearfix:after {
    content:" ";
    display:block;
    clear:both;
}

.card {
    @include border-radius(2px);
    background: #ffffff;
    padding: 16px;
    box-sizing: border-box;
    margin-bottom: 16px;

    &.card-w-title {
        padding-bottom: 32px;
    }

    h1 {
        font-size: 24px;
        font-weight: 400;
        margin: 24px 0;

        &:first-child {
            margin-top: 16px;
        }
    }

    h2 {
        font-size: 22px;
        font-weight: 400;
    }

    h3 {
        font-size: 20px;
        font-weight: 400;
    }

    h4 {
        font-size: 18px;
        font-weight: 400;
    }
}

.nopad {
    padding: 0;

    .ui-panel-content {
        padding: 0;
    }
}

@-webkit-keyframes fadeInDown {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -20px, 0);
    transform: translate3d(0, -20px, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

@keyframes fadeInDown {
  from {
    opacity: 0;
    transform: translate3d(0, -20px, 0);
  }

  to {
    opacity: 1;
    transform: none;
  }
}

@-webkit-keyframes fadeOutUp {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    -webkit-transform: translate3d(0, -20px, 0);
    transform: translate3d(0, -20px, 0);
  }
}

@keyframes fadeOutUp {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    -webkit-transform: translate3d(0, -20px, 0);
    transform: translate3d(0, -20px, 0);
  }
}

.fadeInDown {
    -webkit-animation-name: fadeInDown;
    animation-name: fadeInDown;
}

 .fadeOutUp {
     -webkit-animation-name: fadeOutUp;
     animation-name: fadeOutUp;
}

.ui-shadow-1 {
    -webkit-box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
    -moz-box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
    box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
}

.ui-shadow-2 {
    -webkit-box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
    -moz-box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
}

.ui-shadow-3 {
    -webkit-box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
    -moz-box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
    box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
}

.ui-shadow-4 {
    -webkit-box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
    -moz-box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
    box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
}

.ui-shadow-5 {
    -webkit-box-shadow: 0 19px 38px rgba(0,0,0,0.30), 0 15px 12px rgba(0,0,0,0.22);
    -moz-box-shadow: 0 19px 38px rgba(0,0,0,0.30), 0 15px 12px rgba(0,0,0,0.22);
    box-shadow: 0 19px 38px rgba(0,0,0,0.30), 0 15px 12px rgba(0,0,0,0.22);
}

.ui-g {
    -ms-flex-wrap: wrap;

    &.form-group {
        > div {
            padding: 8px;
        }
    }
}

.ui-panelgrid {
    &.form-group {
        .ui-panelgrid-cell {
            padding: 8px;
        }
    }

    &.ui-panelgrid-blank {
        .ui-grid-responsive {
            .ui-grid-row {
                border: 0 none;
            }
        }
    }
}

.ui-selectoneradio, .ui-selectmanycheckbox  {
    &.form-group {
        .ui-grid-row > div {
            padding: 8px;
        }
    }
}

